// Color system

// brand & section colours
$brand-red: #ee3121;
$brand-primary: #00b2e2;
$brand-accent: #333;
$brand-accent-dim: #a6a6a6;
$brand-disabled: #a6a6a6;
$header-background: #ebebeb;

$pending-claim: #9f26b4;
$registration: #87c440;
$conflict: #ffb600;
$payment: #00b2e2;
$royalty: #00b2e2;
$overview: #888888;

// define $section-colors map
$section-colors: ();
// stylelint-disable-next-line scss/dollar-variable-default
$section-colors: map-merge(
                (
                        "conflict": $conflict,
                        "overview": $overview,
                        "payment": $payment,
                        "pending-claim": $pending-claim,
                        "registration": $registration,
                  //"royalty": $royalty,
                        "withdrawn": $brand-disabled,
                ),
                $section-colors
);

// variable overrides

$primary: $brand-primary;
$secondary: $brand-accent;

// The yiq lightness value that determines when the lightness of color changes from "dark" to "light". Acceptable values are between 0 and 255.
$yiq-contrasted-threshold: 255;

// Components
//
// Define common padding and border radius sizes and more.

$border-style: solid;

$btn-hover-darken-percentage: 7.5%;

$card-header-font-size: 1rem;

// Typography
//
// Font, line-height, and color for body text, headings, and more.

@import "fonts";

$font-size-base: 0.8rem;
$font-size-icons: 1rem;

$headings-font-family: "DINRegular", Arial, sans-serif !important;

// Tables
//
// Customizes the `.table` component with basic values, each used across all table variations.

$table-striped-order: even; // makes table-striped even row shaded

// Navs, Navbar

$navbar-light-bg: $header-background;
