@mixin pane-variation($name) {
  $colour: map_get($section-colors, $name);

  .section-#{$name} {

    .bg-solid {
      background-color: $colour;

      .card-title {
        color: $white;
      }

      .card-link {
        color: $white;

        @include hover-focus-active {
          color: $white;
          text-decoration: underline;
        }
      }
    }

    .card {
      border-color: $colour;
    }

    .card-title {
      color: $colour;
    }

  }
}

@mixin section-variation($name) {
  $colour: map_get($section-colors, $name);
  $hover-colour: darken($colour, $btn-hover-darken-percentage);

  .section-#{$name} {

    a, .btn-link {
      color: $colour;

      @include hover-focus-active {
        color: $hover-colour;
      }
    }

    .bg-primary {
      background-color: $colour !important;
    }

    .btn-primary {
      @include button-variant($colour, $colour);
    }

    .btn-default, .btn-outline-primary {
      @include button-outline-variant($colour);
    }

    .alert-light {
      @include alert-variant(brand-color-level($colour, $alert-bg-level), brand-color-level($colour, $alert-border-level), brand-color-level($colour, $alert-color-level));
    }

    .btn-reset {
      &:focus {
        box-shadow: 0 0 0 $btn-focus-width rgba($colour, .5);
      }
    }

    .form-control, .custom-file-input {
      &:focus {
        border-color: lighten($colour, 25%);
        box-shadow: 0 0 0 $input-btn-focus-width rgba($colour, .25);
      }
    }

    .form-check-icon {
      color: $colour;
    }

    .table {
      th, .th {
        color: $colour;
      }
    }

    .tip {
      &:hover {
        color: $colour;
      }
    }

  }
}

@function brand-color-level($color, $level: 0) {
  $color: $color;
  $color-base: if($level > 0, #000, #fff);
  $level: abs($level);

  @return mix($color-base, $color, $level * $theme-color-interval);
}
