/**
 * Font used for headings
 */
@font-face {
  font-family: 'DINRegular';
  font-weight: normal;
  font-style: normal;
  src: url('../assets/fonts/dinm____-webfont.eot');
  src: url('../assets/fonts/dinm____-webfont.eot#iefix') format('embedded-opentype'),
  url('../assets/fonts/dinm____-webfont.woff') format('woff'),
  url('../assets/fonts/dinm____-webfont.ttf') format('truetype'),
  url('../assets/fonts/dinm____-webfont.svg#DINRegular') format('svg');
}

h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
  text-transform: uppercase;
}
