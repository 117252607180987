.agreement-terms-full {
  h1, h2, h3, h4 {
    font-size: $card-header-font-size;
  }

  .rteindent1 {
    margin-left: $spacer;
  }

  .rteindent2 {
    margin-left: ($spacer*2);
  }

  //ol {
  //  list-style: none;
  //  counter-reset: my-awesome-counter;
  //}

  ol > li {
    //counter-increment: my-awesome-counter;
    margin-bottom: $spacer;
  }

  ol > li::before {
    //content: counter(my-awesome-counter) ". ";
    font-family: $headings-font-family;
    font-weight: bold;
  }
}

.accordion {
  & > .card {
    border-color: $white !important;

    &:not(:last-of-type) {
      margin-bottom: ($spacer * 0.25);
    }

    & > .card-header {
      button {
        // contains text and icon
        text-align: left;
        width: 100%;

        // css background image
        // https://github.com/FortAwesome/Font-Awesome/blob/master/svgs/solid/chevron-circle-down.svg
        background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512' width='512' height='512'%3E%3Cpath fill='rgb(255,255,255)' d='M504 256c0 137-111 248-248 248S8 393 8 256 119 8 256 8s248 111 248 248zM273 369.9l135.5-135.5c9.4-9.4 9.4-24.6 0-33.9l-17-17c-9.4-9.4-24.6-9.4-33.9 0L256 285.1 154.4 183.5c-9.4-9.4-24.6-9.4-33.9 0l-17 17c-9.4 9.4-9.4 24.6 0 33.9L239 369.9c9.4 9.4 24.6 9.4 34 0z'/%3E%3C/svg%3E");
        background-position: right center;
        background-repeat: no-repeat;
        background-size: 1rem;

        &.collapsed {
          // https://github.com/FortAwesome/Font-Awesome/blob/master/svgs/solid/chevron-circle-right.svg
          background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512' width='512' height='512'%3E%3Cpath fill='rgb(255,255,255)' d='M256 8c137 0 248 111 248 248S393 504 256 504 8 393 8 256 119 8 256 8zm113.9 231L234.4 103.5c-9.4-9.4-24.6-9.4-33.9 0l-17 17c-9.4 9.4-9.4 24.6 0 33.9L285.1 256 183.5 357.6c-9.4 9.4-9.4 24.6 0 33.9l17 17c9.4 9.4 24.6 9.4 33.9 0L369.9 273c9.4-9.4 9.4-24.6 0-34z'/%3E%3C/svg%3E");
        }
      }

      &.bg-solid {
        background-color: $brand-primary;
        border-color: $brand-primary;
        border-radius: $border-radius !important;
        color: $white;
        //margin-bottom: ($spacer*0.5);

        button {
          // contains text and icon
          color: $white;
        }
      }

      .card-title {
        display: inline-block;
        float: left;
        vertical-align: text-top;
      }
    }
  }
}
