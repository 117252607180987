@import "variables";
@import "../../../node_modules/bootstrap/scss/variables";

// base link style
a, .btn-link {
  text-decoration: none;
  @include transition($btn-transition);
  @include hover-focus-active() {
    text-decoration: underline;
  }
}

.btn-link {
  color: $brand-accent;
  border: none;
  padding: 0;
}

.btn {
  font-family: $headings-font-family;
  font-size: $font-size-sm;
  margin-right: ($spacer * 0.5);
  padding-top: ($input-btn-padding-x / 1.5);
  text-transform: uppercase;

  &:last-child {
    margin-right: 0;
  }

  @include hover-focus-active {
    text-decoration: none !important;
  }

  &.btn-link, &.btn-reset {
    color: $brand-accent !important;
    @include hover-focus-active {
      text-decoration: underline !important;

    }
  }
}

.btn-group > .btn {
  margin-right: 0;
}

.action-group {
  text-align: right;
}

.await-button-wrapper {
  position: relative;

  .panel-body {
    padding: 0.5rem;
  }

  .lib-spinner,
  .lib-spinner-backdrop {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  .lib-spinner-backdrop {
    background-color: #fff;
    opacity: 0.7;
  }

  .fa-spinner {
    font-size: 1.1rem;
    opacity: 0.75;
  }

  margin-right: 0.5rem;

  &:last-child {
    margin-right: 0.5rem;
  }

}

.card {
  //border-color: $primary;
  margin-bottom: $card-spacer-x;
}

.card-footer, .modal-footer {
  background-color: transparent;
  border-top: none;
}

.card-header, .modal-header {
  background-color: transparent;
  border-bottom: none;
}

.card-title {
  //color: $primary;
  margin-bottom: 0;
}

.control-label,
.form-group label {
  font-weight: bold;
}

.form-required {
  color: $danger;
  margin-left: ($spacer*0.1);
}

.group-wrapper {
  margin-bottom: $card-spacer-y;
}

.modal-footer > .float-left {
  margin-right: auto;
}

::placeholder {
  color: #999999 !important;
  font-style: italic;
}

.header {
  margin-top: 1rem;
  margin-bottom: 2rem;
}

/* Sticky footer styles
-------------------------------------------------- */
$sticky-footer-height: 100px;

html {
  position: relative;
  min-height: 100%;
}

body {
  margin-bottom: $sticky-footer-height; /* Margin bottom by footer height */
}

.footer {
  bottom: 0;
  background: rgba(255, 255, 255, 0.75);
  margin-top: 2rem;
  min-height: $sticky-footer-height; /* Set the fixed height of the footer here */
  position: absolute;
  width: 100%;
  white-space: nowrap;

  .nav {
    .nav-item {
      padding: 0 $nav-link-padding-y;

      &:first-of-type {
        padding-left: 0;
      }

      &:last-of-type {
        padding-right: 0;
      }
    }
  }
}

.footer-bottom {
  padding-top: $spacer;
}

#copyright-info {
  text-align: right;
}
